<template>
  <div class="basic-footer">
    <div class="contact-list">
      <div class="cl-item">
        <div>新人指南</div>
        <a href="/#/news/detail?id=164&hide-breadcrumb=1" target="_blank">免费注册</a>
        <a href="/#/news/detail?id=165&hide-breadcrumb=1" target="_blank">认证流程</a>
        <a href="/#/news/detail?id=166&hide-breadcrumb=1" target="_blank">所需资质</a>
      </div>
      <div class="cl-item">
        <div>配送/支付</div>
        <a href="/#/news/detail?id=167&hide-breadcrumb=1" target="_blank">物流配送</a>
        <a href="/#/news/detail?id=168&hide-breadcrumb=1" target="_blank">支付方式</a>
      </div>
      <div class="cl-item">
        <div>质量/法律</div>
        <a href="/#/news/detail?id=169&hide-breadcrumb=1" target="_blank">质量保证</a>
        <a href="/#/news/detail?id=136&hide-breadcrumb=1" target="_blank">隐私政策</a>
        <a href="/#/news/detail?id=163&hide-breadcrumb=1" target="_blank">法律声明</a>
      </div>
      <div class="cl-item">
        <div>关于我们</div>
        <a href="/#/news/detail?id=193&hide-breadcrumb=1" target="_blank">青牛简介</a>
        <a href="/#/news/detail?id=171&hide-breadcrumb=1" target="_blank">联系我们</a>
      </div>
      <div class="cl-item" style="width: 200px">
        <div>服务电话</div>
        <div class="tel1" style="color: #333333"> 客服热线 0797-730-2078</div>
        <div class="tel1" style="color: #333333"> 质量中心 19979707818 </div>
        <div class="tel1" style="color: #333333"> 产品中心 19979726818 </div>
      </div>
      <div class="cl-item">
        <div>服务时间</div>
        <div class="tel1" style="color: #333333">8:00-17:30</div>
      </div>
    </div>
    <div class="logo-wrap flex">
      <div>
        <div class="tip app-tip">APP下载</div>
        <img src="@/assets/image/common/qrcode-app.png" alt="" />
      </div>
      <!-- <div>
        <div class="tip mp-tip">微信小程序</div>
        <img src="@/assets/image/common/qrcode-mp.png" alt="" />
      </div> -->
      <div>
        <div class="tip h5-tip">微信公众号</div>
        <img src="@/assets/image/common/qrcode-h5.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="less" scoped>
  .basic-footer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: @baseMainWidth;
    box-sizing: border-box;
    margin: 0 auto;
    .contact-list {
      display: flex;
    }
    .cl-item {
      width: 140px;
      color: @regularColor;
      text-align: left;
      > div:first-child {
        font-weight: 700;
        color: @primaryColor;
      }
      > a,
      > div {
        display: block;
        height: 32px;
        line-height: 32px;
        color: @regularColor;
      }
      .tel {
        color: @redColor;
        font-size: 20px;
        height: 32px;
        line-height: 32px;
      }
    }
    .logo-wrap {
      > div {
        margin-left: 40px;
      }
      img {
        width: 90px;
      }
      .tip {
        color: @whiteColor;
        padding-top: 3px;
        height: 36px;
      }
      .app-tip {
        background: url(~@/assets/image/common/app-tip.png) no-repeat 0 0;
      }
      .mp-tip {
        background: url(~@/assets/image/common/mp-tip.png) no-repeat 0 0;
      }
      .h5-tip {
        background: url(~@/assets/image/common/h5-tip.png) no-repeat 0 0;
      }
    }
  }
</style>
