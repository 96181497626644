import { createStore } from 'vuex';
import Storage from '@/utils/storage';
import Router from '@/router';
import Cart from '@/common/api/service/cart';
import User from '@/common/api/service/user';
import Common from '@/common/api/service/common';

export default createStore({
  state: {
    //是否登录
    isLogin: false,
    token: '',
    //用户信息
    userInfo: {},
    // 审核状态
    regStatus: {},
    //购物车数量
    cartCount: 0,
    //牛币商城购物车数量
    cartNbCount: 0,
    loginAdvList: [],
  },
  mutations: {
    /**
     * 初始化数据
     * @param state
     */
    setUserInfo(state: any, data) {
      state.userInfo = data;
    },
    setToken(state: any, token) {
      state.isLogin = true;
      state.token = token;
    },
    /**
     * 登录
     * @param state
     * @param data
     */
    login(state: any, data) {
      state.isLogin = true;
      state.token = data.token;
      state.userInfo = data.user_info;
    },
    /**
     * 退出登录
     * @param state
     */
    logout(state: any) {
      state.isLogin = false;
      state.token = '';
      state.userInfo = {};
      state.regStatus = {};
      Storage.remove('userData');
      Storage.remove('isLogin');
      Router.push('/login');
    },
    /**
     * 更新购物车数量
     * @param state
     * @param count
     */
    setCartCount(state: any, count: number) {
      state.cartCount = count;
    },
    setCartNbCount(state: any, count: number) {
      state.cartNbCount = count;
    },
    setLoginAdvList(state: any, data) {
      state.loginAdvList = data;
    },
    setRegStatus(state: any, data) {
      state.regStatus = data;
    },
  },
  actions: {
    async apiGetCartCountFn({ commit }) {
      const res = await Cart.apiGetCartCount();
      commit('setCartCount', res.result.cart_count);
    },
    async apiGetCartNbCountFn({ commit }) {
      const res = await Cart.apiGetCartNbCount();
      commit('setCartNbCount', res.result.cart_count);
    },
    async apiUserLoginoutFn({ commit }) {
      await User.apiUserLoginout();
      commit('logout');
    },
    async apiGetRegStatusFn({ commit }) {
      const regRes: any = await User.apiGetRegStatus({}, { useLoading: false });
      commit('setRegStatus', regRes.result);
    },
    async initData({ commit }, token) {
      let userData = Storage.get('userData') as any;
      if (!userData) {
        userData = {
          token: token,
        };
      }
      if (userData.token) {
        commit('setToken', userData.token);
        const res: any = await User.apiMyInfo().catch((e) => {
          console.error(e);
        });
        //console.log('res',res);
        userData = {
          ...userData,
          ...res.result,
        };
        commit('setUserInfo', userData);
      }
    },
    async apiGetLoginAdv({ commit }) {
      const res = await Common.apiGetLoginAdv({
        positionCode: 'login-bj',
        num: '10',
        type: '1',
      });
      commit('setLoginAdvList', res.result);
    },
  },
  modules: {},
});
