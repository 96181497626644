import axios from '../axios';

export interface ApiGetDownloadFirst {
  goodsIds: string;
}
export interface ApiGetDownloadReport extends ApiGetDownloadFirst {
  batchNumbe: string;
}

interface apiGetLoginAdvModel {
  positionCode: string;
  num: string;
  type: string;
}

export default class Common {
  //   /**
  //    * 文件上传
  //    * @param data
  //    * @returns
  //    */
  //   public static async apiUpload(data: any): Promise<any> {
  //     return await axios({
  //       method: "post",
  //       url: "/api_v2/upload?type=1",
  //       data,
  //       headers: {
  //         "Content-Type":
  //           "mmultipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  //       },
  //       //   headers: { "content-type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW" },
  //     });
  //   }

  /**
   * 厂检报告下载
   * @param params
   * @returns
   */
  public static async apiGetDownloadReport(
    params: ApiGetDownloadReport,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/download_report',
      params,
      ...option,
    });
  }

  /**
   * 厂检报告批量下载
   * @param params
   * @returns
   */
  public static async apiGetDownloadReportBatch(
    params: { report_json: string },
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/download_report_batch',
      params,
      ...option,
    });
  }

  /**
   * 首营资料下载
   * @param params
   * @returns
   */
  public static async apiGetDownloadFirst(
    params: ApiGetDownloadFirst,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/download_first',
      params,
      ...option,
    });
  }

  /**
   * 登录广告图
   * @param params
   * @returns
   */
  public static async apiGetLoginAdv(params: apiGetLoginAdvModel, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/get_login_adv',
      params,
      ...option,
    });
  }

  // 文章列表
  public static async apiGetArticleList(params: { type: string }, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/article_list',
      params,
      ...option,
    });
  }

  // 文章详情
  public static async apiGetHelpByid(params: { id: string }, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/get_help_byid',
      params,
      ...option,
    });
  }

    // 药品展示列表
    public static async apiGetDrugList(params: { type: string }, option: any = {}): Promise<any> {
      return await axios({
        method: 'get',
        url: '/api_v2/index_get_goods',
        params,
        ...option,
      });
    }

    //报告分析
    public static async apiGetReportAnalysisList(params: { type: string }, option: any = {}): Promise<any> {
      return await axios({
        method: 'get',
        url: '/api_v2/index_article_list',
        params,
        ...option,
      });
    }

    //支付 index create_pay
    public static async apiGetcreatePay(params: { type: string }, option: any = {}): Promise<any> {
      return await axios({
        method: 'get',
        url: '/api_v2/index_create_pay',
        params,
        ...option,
      });
    }
    

}
